import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const MentionsLegales = () => (
  <Layout langKey={"en"}>
    <SEO title="Legal information" />
    <section className="section">
      <div className="container">
        <h1>Legal information</h1>
        <h2>Publisher</h2>
        <p>
          <strong>Aprogsys</strong>
          <br />
          With a capital of 60 000 €<br />
          14b rue la Fayette
          <br />
          25000 Besançon
          <br />
          Phone: +33 (0)3 81 47 70 38
          <br />
          SIRET: 437.768.385.00019
        </p>
        <h2>Hosting company</h2>
        <p>
          The server of the Aprogsys site is hosted in France by:<br /><br/>
          <strong>OVH</strong>
          <br />
          2 rue Kellermann
          <br />
          59100 Roubaix
          <br />
          FRANCE
          <br />
          <a href="https://www.ovh.com">www.ovh.com</a>
        </p>
        <h2>Personal data</h2>
        <p>The www.aprogsys.com website does not store tracking cookies and does not offer a user account.</p>
      </div>
    </section>
  </Layout>
)

export default MentionsLegales
